import {
  introActivePrimeDashboardSteps,
  introSystemPerformanceDashboardSteps,
  introMergeDuplicateSetsReviewTableSteps,
  introOnYourPlateSteps,
  introAutoMergeReviewTableSteps,
  introDuplicateSetsReviewTableSteps,
} from '@/services/intro'

export default [
  {
    path: '',
    name: 'root',
    component: () =>
      import(
        /* webpackChunkName: "view-admin-dashboard" */ '@/views/dashboard/AdminDashboard.vue'
      ),
    meta: {
      breadcrumb: [],
      pageType: 'Dashboard',
      pageTitle: 'Admin Dashboard',
      introSteps: introActivePrimeDashboardSteps,
    },
  },
  {
    path: 'system-performance',
    component: {
      render(h) {
        return h('router-view')
      },
    },
    children: [
      {
        path: '',
        name: 'root/system-performance',
        component: () =>
          import(
            /* webpackChunkName: "view-admin-performance" */ '@/views/dashboard/AdminDashboardPerformance.vue'
          ),
        meta: {
          breadcrumb: [
            {
              name: 'Return to Central Dashboard',
              link: '/dashboard',
              disabled: false,
            },
            { name: 'System Performance Dashboard', disabled: true },
          ],
          pageType: 'Dashboard',
          pageTitle: 'Performance Dashboard',
          introSteps: introSystemPerformanceDashboardSteps,
        },
      },
      {
        path: 'merged-dupe-sets',
        name: 'root/system-performance/merged-dupe-sets',
        component: () =>
          import(
            /* webpackChunkName: "view-admin-dupe-sets" */ '@/views/dashboard/AdminDashboardPerformanceMergedToReview.vue'
          ),
        meta: {
          breadcrumb: [
            {
              name: 'Return to Central Dashboard',
              link: '/dashboard',
              disabled: false,
            },
            {
              name: 'System Performance Dashboard',
              link: '/dashboard/system-performance',
              disabled: false,
            },
            { name: 'Merged Duplicate Sets', disabled: true },
          ],
          pageType: 'Dashboard',
          pageTitle: 'Review Merge Duplicate Sets',
          introSteps: introMergeDuplicateSetsReviewTableSteps,
        },
      },
      {
        path: 'jobs',
        component: {
          render(h) {
            return h('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'root/system-performance/jobs',
            component: () =>
              import(
                /* webpackChunkName: "view-admin-jobs" */ '@/views/dashboard/AdminDashboardPerformanceJobs.vue'
              ),
            meta: {
              breadcrumb: [
                {
                  name: 'Return to Central Dashboard',
                  link: '/dashboard',
                  disabled: false,
                },
                {
                  name: 'System Performance Dashboard',
                  link: '/dashboard/system-performance',
                  disabled: false,
                },
                { name: 'Jobs', disabled: true },
              ],
              pageType: 'Dashboard',
              pageTitle: 'Jobs',
            },
          },
          {
            path: ':jobId',
            name: 'root/system-performance/jobs/details',
            component: () =>
              import(
                /* webpackChunkName: "view-admin-jobs-details" */ '@/views/dashboard/AdminDashboardPerformanceJobsDetails.vue'
              ),
            meta: {
              breadcrumb: [
                {
                  name: 'Return to Central Dashboard',
                  link: '/dashboard',
                  disabled: false,
                },
                {
                  name: 'System Performance Dashboard',
                  link: '/dashboard/system-performance',
                  disabled: false,
                },
                {
                  name: 'Jobs',
                  link: '/dashboard/system-performance/jobs',
                  disabled: false,
                },
                { name: (route) => route.params.jobId, disabled: true },
              ],
              pageType: 'Dashboard',
              pageTitle: 'Jobs',
            },
          },
        ],
      },
      {
        path: 'merge-errors',
        component: {
          render(h) {
            return h('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'root/system-performance/merge-errors-list',
            component: () =>
              import(
                /* webpackChunkName: "view-admin-merge-erros-list" */ '@/views/dashboard/AdminDashboardPerformanceMergeErrorsList.vue'
              ),
            meta: {
              breadcrumb: [
                {
                  name: 'Return to Central Dashboard',
                  link: '/dashboard',
                  disabled: false,
                },
                {
                  name: 'System Performance Dashboard',
                  link: '/dashboard/system-performance',
                  disabled: false,
                },
                { name: 'Merge Errors List', disabled: true },
              ],
              pageType: 'Dashboard',
              pageTitle: 'Merge Errors List',
            },
          },
        ],
      },
    ],
  },
  {
    path: 'on-my-plate',
    component: {
      render(h) {
        return h('router-view')
      },
    },
    children: [
      {
        path: '',
        name: 'root/on-my-plate',
        component: () =>
          import(
            /* webpackChunkName: "view-admin-plate" */ '@/views/dashboard/AdminDashboardPlate.vue'
          ),
        meta: {
          breadcrumb: [
            {
              name: 'Return to Central Dashboard',
              link: '/dashboard',
              disabled: false,
            },
            { name: 'On My Plate', disabled: true },
          ],
          pageType: 'Dashboard',
          pageTitle: 'On My Plate',
          introSteps: introOnYourPlateSteps,
        },
      },
      {
        path: 'dupe-sets-to-review',
        name: 'root/on-my-plate/dupe-sets-to-review',
        component: () =>
          import(
            /* webpackChunkName: "view-admin-dupe-sets" */ '@/views/dashboard/AdminDashboardPlateDupeSetsToReview.vue'
          ),
        meta: {
          breadcrumb: [
            {
              name: 'Return to Central Dashboard',
              link: '/dashboard',
              disabled: false,
            },
            {
              name: 'On My Plate',
              link: '/dashboard/on-my-plate',
              disabled: false,
            },
            { name: 'Duplicate Sets to be reviewed', disabled: true },
          ],
          pageType: 'Dashboard',
          pageTitle: 'Duplicate Sets to Review On My Plate',
          introSteps: introDuplicateSetsReviewTableSteps,
        },
      },
      {
        path: 'auto-merges-to-review',
        name: 'root/on-my-plate/auto-merges-to-review',
        component: () =>
          import(
            /* webpackChunkName: "view-admin-auto-merge" */ '@/views/dashboard/AdminDashboardPlateAutoMergesToReview.vue'
          ),
        meta: {
          breadcrumb: [
            {
              name: 'Return to Central Dashboard',
              link: '/dashboard',
              disabled: false,
            },
            {
              name: 'On My Plate',
              link: '/dashboard/on-my-plate',
              disabled: false,
            },
            { name: 'Auto-merges to review', disabled: true },
          ],
          pageType: 'Dashboard',
          pageTitle: 'Auto-merges to Review On My Plate',
          introSteps: introAutoMergeReviewTableSteps,
        },
      },
      {
        path: 'bulk-grid',
        name: 'root/on-my-plate/bulk-grid',
        component: () =>
          import(
            /* webpackChunkName: "view-bulk-grid" */ '@/views/dashboard/BulkGrid.vue'
          ),
        meta: {
          breadcrumb: [
            {
              name: 'Return to Central Dashboard',
              link: '/dashboard',
              disabled: false,
            },
            {
              name: 'On My Plate',
              link: '/dashboard/on-my-plate',
              disabled: false,
            },
            {
              name: 'Bulk Grid',
              disabled: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: 'sales-rep',
    name: 'root/sales-rep-dashboard',
    component: () =>
      import(
        /* webpackChunkName: "view-salesrep-dashboard" */ '@/views/dashboard/SalesRepDashboard.vue'
      ),
    meta: {
      breadcrumb: [
        {
          name: 'Return to Central Dashboard',
          link: '/dashboard',
          disabled: false,
        },
        { name: 'Sales Representative Dashboard', disabled: true },
      ],
      pageType: 'Dashboard',
      pageTitle: 'Sales Representative',
    },
  },
  {
    path: 'sales-manager',
    name: 'root/sales-manager-dashboard',
    component: () =>
      import(
        /* webpackChunkName: "view-salesmanager-dashboard" */ '@/views/dashboard/SalesManagerDashboard.vue'
      ),
    meta: {
      breadcrumb: [
        {
          name: 'Return to Central Dashboard',
          link: '/dashboard',
          disabled: false,
        },
        { name: 'Sales Manager Dashboard', disabled: true },
      ],
      pageType: 'Dashboard',
      pageTitle: 'Sales Manager',
    },
  },
]
