import store from '../store'

export default [
  {
    path: '',
    name: 'admin-home',
    redirect: { name: 'admin-detect-and-merge' },
    component: () =>
      import(
        /* webpackChunkName: "view-cleandata" */ '@/views/admin/AdminHome.vue'
      ),
  },
  {
    path: 'account',
    name: 'admin-account',
    component: () =>
      import(
        /* webpackChunkName: "view-admin-account" */ '@/views/admin/AdminAccount.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (store.state.auth.user.roleName !== 'Admin') {
        return next('/')
      } else {
        return next()
      }
    },
  },
  {
    path: 'security',
    name: 'admin-security',
    component: () =>
      import(
        /* webpackChunkName: "view-security" */ '@/views/admin/AdminSecurity.vue'
      ),
  },
  {
    path: 'help',
    name: 'admin-help',
    component: () =>
      import(/* webpackChunkName: "view-help" */ '@/views/admin/AdminHelp.vue'),
  },
  {
    path: 'users',
    name: 'admin-user-management',
    component: () =>
      import(
        /* webpackChunkName: "view-manageusers" */ '@/views/admin/AdminManageUsers.vue'
      ),
  },
  {
    path: 'roles',
    name: 'admin-permission-rules',
    component: () =>
      import(
        /* webpackChunkName: "view-roles" */ '@/views/admin/AdminRoles.vue'
      ),
  },
  {
    path: 'business-rules',
    component: {
      render(h) {
        return h('router-view')
      },
    },
    children: [
      {
        path: '',
        name: 'admin-business-rules-home',
        component: () =>
          import(
            /* webpackChunkName: "view-settings-home" */ '@/views/admin/AdminBusinessRules.vue'
          ),
        meta: {
          breadcrumb: [
            { name: 'Active Prime', link: '/', disabled: false },
            { name: 'Business Rules', disabled: true },
          ],
        },
      },
      {
        path: 'block-on-entry',
        component: {
          render(h) {
            return h('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'admin-block-on-entry',
            component: () =>
              import(
                /* webpackChunkName: "view-block-on-entry" */ '@/views/admin/AdminBusinessRulesBlockOnEntry.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'Active Prime', link: '/', disabled: false },
                {
                  name: 'Business Rules',
                  link: '/admin/business-rules',
                  disabled: false,
                },
                { name: 'Block on entry', disabled: true },
              ],
            },
          },
        ],
      },
      {
        path: 'detect-and-merge',
        component: {
          render(h) {
            return h('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'admin-detect-and-merge',
            component: () =>
              import(
                /* webpackChunkName: "view-settings-home" */ '@/views/admin/AdminBusinessRulesDetectAndMerge.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'Active Prime', link: '/', disabled: false },
                {
                  name: 'Business Rules',
                  link: '/admin/business-rules',
                  disabled: false,
                },
                { name: 'Detect and Merge', disabled: true },
              ],
            },
          },
          {
            path: ':directorRuleId/:action',
            name: 'admin-manage-business-rule',
            component: () =>
              import(
                /* webpackChunkName: "view-managebusinessrule" */ '@/views/admin/AdminBusinessRulesDetectAndMergeDetails.vue'
              ),
            meta: {
              isSideBarHidden: true,
              breadcrumb: [
                { name: 'Active Prime', link: '/', disabled: false },
                {
                  name: 'Business Rules',
                  link: '/admin/business-rules',
                  disabled: false,
                },
                { name: 'Detect and Merge', disabled: true },
              ],
            },
          },
        ],
      },
      {
        path: 'validation',
        component: {
          render(h) {
            return h('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'admin-validation',
            component: () =>
              import(
                /* webpackChunkName: "view-validation" */ '@/views/admin/AdminBusinessRulesValidation.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'Active Prime', link: '/', disabled: false },
                {
                  name: 'Business Rules',
                  link: '/admin/business-rules',
                  disabled: false,
                },
                { name: 'Validation', disabled: true },
              ],
            },
          },
        ],
      },
      {
        path: 'mass-update',
        component: {
          render(h) {
            return h('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'admin-mass-update',
            component: () =>
              import(
                /* webpackChunkName: "view-mass-update" */ '@/views/admin/AdminBusinessRulesMassUpdate.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'Active Prime', link: '/', disabled: false },
                {
                  name: 'Business Rules',
                  link: '/admin/business-rules',
                  disabled: false,
                },
                { name: 'Mass update', disabled: true },
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: 'general-settings',
    component: {
      render(h) {
        return h('router-view')
      },
    },
    children: [
      {
        path: '',
        name: 'admin-general-settings-home',
        component: () =>
          import(
            /* webpackChunkName: "view-settings-home" */ '@/views/admin/AdminGeneralSettings.vue'
          ),
      },
      {
        path: 'setup',
        name: 'admin-general-settings-setup',
        component: () =>
          import(
            /* webpackChunkName: "view-settings-setup" */ '@/views/admin/AdminGeneralSettingsSetup.vue'
          ),
      },
      {
        path: 'governance-rules',
        name: 'admin-general-settings-governance-rules',
        component: () =>
          import(
            /* webpackChunkName: "view-governance-rules" */ '@/views/admin/AdminGeneralSettingsGovernanceRules.vue'
          ),
        meta: {
          breadcrumb: [
            {
              name: 'General Settings',
              link: '/admin/general-settings',
              disabled: false,
            },
            { name: 'Governance Rules', disabled: true },
          ],
        },
      },
      {
        path: 'notifications',
        name: 'admin-general-settings-notification',
        component: () =>
          import(
            /* webpackChunkName: "view-settings-notifications" */ '@/views/admin/AdminGeneralSettingsNotifications.vue'
          ),
      },
    ],
  },
]
